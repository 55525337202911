import axios from "axios";

const getCalendarData = (miniCalendar) => {
  const calendarOption = getElementValue("calendarOption");
  return axios
  .get("/api/calendar/getcalendardata", {
    params: {
      calendarOption: calendarOption,
      language: getElementValue("languageCode"),
      startPageId: getElementValue("startPageId"),
      miniCalendar: miniCalendar,
    }
  })
  .then((response) => {
    return response.data;
  });
};

const getElementValue = (id) => {
  let element = document.getElementById(id);

  if (element) {
    return element.value;
  }
};

const removeSlash = (variable) => {
  if (variable.endsWith('/')) {
    variable = variable.slice(0, -1);
  }

  return variable;
}

const monthsSwedish = [
  "januari", "februari", "mars", "april", "maj", "juni",
  "juli", "augusti", "september", "oktober", "november", "december"
];

const monthsEnglish = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export {
  getCalendarData,
  monthsSwedish,
  monthsEnglish
};
