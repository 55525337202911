<template>
  <div class="calendar__wrapper">
    <div class="splide">
      <div class="splide__track">
        <ul class="splide__list">
          <li v-for="(month, key, index) in filteredCalendarEvents" class="splide__slide">
            <div v-if="!miniCalendar" class="calendar__header-section">
              <h2 class="calendar__current-month-heading">{{ key }}</h2>
              <button
                  :disabled="index === 0"
                  type="button"
                  class="calendar__month-button calendar__month-button--prev"
                  aria-controls="splide01-track"
                  @click="prevSlide"
              >
                <span class="calendar__month-button-icon icon icon--arrow-head-left"></span>
                <span class="calendar__month-button-text heading--medium" v-if="index !== 0"><span class="sr-only">{{this.translations.PreviousMonth}}: </span>{{Object.keys(filteredCalendarEvents)[index - 1]}}</span>
                <span class="calendar__month-button-text sr-only" v-else>{{this.translations.PreviousMonth}}</span>
              </button>
              <button
                  :disabled="index + 1 === numberOfMonths"
                  type="button"
                  class="calendar__month-button calendar__month-button--next"
                  aria-controls="splide01-track"
                  @click="nextSlide"
              >
                <span class="calendar__month-button-text heading--medium" v-if="index + 1 < numberOfMonths"><span class="sr-only">{{this.translations.NextMonth}}: </span>{{Object.keys(filteredCalendarEvents)[index + 1]}}</span>
                <span class="calendar__month-button-text sr-only" v-else>{{this.translations.NextMonth}}</span>
                <span class="calendar__month-button-icon icon icon--arrow-head-right"></span>
              </button>
            </div>
            <ul :class="miniCalendar ? 'minicalendar' : ''" class="calendar__container content-area content-area--thirds">
              <Calendar
                v-for="calendar in month"
                :calendar="calendar"
                :translations="translations"
                :culture="culture"
                @onError="onError"
              />
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "./calendar.vue";
import Splide from '@splidejs/splide';
import { monthsEnglish, monthsSwedish } from './../services/calendarService';

export default {
  name: "CalendarList",
  props: {
    filteredCalendarEvents: [],
    translations: {},
    culture: "",
    miniCalendar: false,
  },
  components: {
    Calendar: Calendar,
  },
  data() {
    return {
      splide: {},
      monthsAsStrings: this.culture === "sv" ? monthsSwedish : monthsEnglish
    };
  },
  mounted() {
    this.initSplide();
  },
  methods: {
    onError: function (error) {
      this.$emit("onError", error);
    },
    initSplide: function () {
      const options = {
        type: 'fade',
        arrows: false,
        keyboard: 'focused',
        pagination: false
      };

      this.splide = new Splide(('.splide'), options);
      this.splide.mount();

      this.goToCurrentMonth();
    },
    nextSlide: function () {
      this.splide.go('>');
    },
    prevSlide: function () {
      this.splide.go('<');
    },
    goToCurrentMonth: function () {
      // Go to current month in the splide
      Object.keys(this.filteredCalendarEvents).forEach((month, index) => {
        let currentMonth = (new Date()).getMonth();
        
        if (month === this.monthsAsStrings[currentMonth]) {
          this.splide.go(index);
        }
      });
    }
  },
  computed: {
    numberOfMonths: function () {
      return Object.keys(this.filteredCalendarEvents).length;
    },
    showMiniCalendar: function () {
      return this.miniCalendar;
    }
  },
  watch: {
    filteredCalendarEvents: {
      handler: function () {
        // Use setTimeout to make sure the DOM is updated before destroying and reinitializing the splide
        setTimeout(() => {
          this.splide.destroy(true);
          this.initSplide();
        });
      }
    }
  }
};
</script>
